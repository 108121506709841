<template>
  <q-form ref="editForm">
    <c-card title="기본정보" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            required
            :readonly="true"
            :editable="editable"
            :afterIcon="!disabled ? [
              { name: 'search', click: true, callbackName: 'searchSop', color: 'teal' },
              { name: 'close', click: true, callbackName: 'removeSop', color: 'red' }
            ] : null"
            label="관찰작업 / 공정"
            name="sopProcessName"
            v-model="sopProcessName"
            @searchSop="openSop"
            @removeSop="removeSop">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            required
            :editable="editable"
            :disabled="disabled"
            type="date"
            label="관찰일"
            name="ctoDate"
            v-model="cto.ctoDate"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            required
            :editable="editable"
            :disabled="disabled"
            :minuteStep="10"
            type="time"
            label="소요시간"
            name="ctoTime"
            v-model="cto.ctoTime"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-multi-field
            required
            :editable="editable"
            :disabled="disabled"
            :data="cto"
            label="관찰자"
            name="observeUserId"
            v-model="cto.observeUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-text
            required
            :editable="editable"
            :disabled="disabled"
            suffix="명"
            type="number"
            label="관찰인원"
            name="observeCount"
            v-model="cto.observeCount">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            required
            :disabled="disabled || isOld"
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="cto.plantCd"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="작업구역"
            name="workArea"
            v-model="cto.workArea">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-card title="총평" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="우선사항"
            name="priorityContents"
            v-model="cto.priorityContents">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="개선사항"
            name="improveContents"
            v-model="cto.improveContents"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-multi-select
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="CTO_WORK_CHARACTER_CD"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="workCharacteristics"
            label="작업특성"
            v-model="cto.workCharacteristics">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-multi-select
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="CTO_HUMAN_FACTOR_CD"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="humanFactors"
            label="인적요소"
            v-model="cto.humanFactors">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-multi-select
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="CTO_CORE_ACTION_ITEMS_CD"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="coreActionItems"
            label="핵심행동항목"
            v-model="cto.coreActionItems">
          </c-multi-select>
        </div>
      </template>
    </c-card>
    <c-table
      ref="activityTable"
      title="핵심행동"
      :columns="grid.columns"
      :data="cto.activityModels"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      noDataLabel="핵심행동을 추가하세요."
      :hideBottom="true"
      :isTitle="true"
      :editable="editable&&!disabled"
      rowKey="sopCtoActivityId"
      selection="multiple"
    >
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn v-if="editable && !disabled" :showLoading="false" label="추가" icon="add" @btnClicked="addActivity" />
          <c-btn v-if="editable && !disabled && cto.activityModels.length > 0" :showLoading="false"  label="제외" icon="remove" @btnClicked="removeActivity" />
        </q-btn-group>
      </template>
    </c-table>
    <c-table
      v-if="isOld"
      ref="table"
      :isTitle="true"
      title="체크리스트 목록"
      :columns="gridCheckList.columns"
      :data="cto.ctoChecklistModels"
      :filtering="false"
      :gridHeightAuto="true"
      :columnSetting="false"
      :usePaging="false"
      noDataLabel="체크리스트를 추가하세요."
      :hideBottom="true"
      :editable="editable&&!disabled"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='checklistName'">
          <font v-if="props.row.disable" style="font-style: italic;font-size:15px;"> 
            <b>{{props.row.checklistName}}</b>
          </font>
          <font v-else>
            {{props.row.checklistName}}
          </font>
        </template>
        <template v-if="col.name==='selectValue'">
          <c-select
            stype="tableselect"
            v-if="!props.row.disable"
            :disabled="disabled"
            codeGroupCd="CTO_GOOD_BAD_CD"
            :editable="editable"
            type="edit"
            itemValue="code"
            itemText="codeName"
            v-model="props.row['selectValue']"
            @datachange="datachange(props)"
            >
          </c-select>
        </template>
        <template v-else-if="col.name==='customCol' && !props.row.disable">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable&&!disabled"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="sopCtoChecklistId"
            ibmTaskTypeCd="ITT0000060"
            ibmTaskUnderTypeCd="ITTU000095"
            :acceptBtnShow="true"
            @imprChange="imprChange"
          >
            <template v-slot:customArea="{ props }">
              <div v-if="!props.row.disable" class="col-6">
                <div class="mini-dailog-title">비고</div>
              </div>
              <div v-if="!props.row.disable" class="col-12">
                <c-text
                  :editable="editable&&!disabled"
                  label=""
                  name="remarks"
                  v-model="props.row.remarks">
                </c-text>
              </div>
            </template>
          </component>
        </template>
      </template>
    </c-table>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn 
            v-show="!disabled && isOld && editable"
            :flat="true"
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="cto"
            mappingType="PUT"
            label="완료" 
            icon="check"
            @beforeAction="completeCto"
            @btnCallback="completeCallback" />
          <c-btn
            v-show="!disabled && editable"
            :flat="true"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="cto"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveCto"
            @btnCallback="saveCallback" 
          />
          <c-btn 
            v-show="!disabled && isOld && editable" 
            :flat="true" 
            label="삭제" 
            icon="delete_forever" 
            @btnClicked="remove" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'ctoInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopCtoId: '',
      }),
    },
    cto: {
      type: Object,
      default: () => ({
        sopCtoId: '',
        plantCd: '',
        ctoDate: '',
        ctoTime: '',
        observeUserId: '',
        observeCount: '',
        mdmSopId: '',
        workArea: '',
        ctoCompleteFlag: 'N',
        improveContents: '',
        priorityContents: '',
        managerUserId: '',
        sopName: '',
        processName: '',
        regUserId: '',
        chgUserId: '',
        workCharacteristics: '',
        humanFactors: '',
        coreActionItems: '',
        activityModels: [],
        ctoChecklistModels: [],
        ctoImproveModels: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'mainActivity',
            field: 'mainActivity',
            label: '핵심행동',
            align: 'left',
            style: 'width:50%',
            type: 'text',
            sortable: false,
          },
          {
            name: 'goodBadCd',
            field: 'goodBadCd',
            label: '양호/불량/해당없음',
            type: 'select',
            setHeader: true,
            style: 'width:20%',
            align: 'center',
            codeGroupCd: 'CTO_GOOD_BAD_CD',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            type: 'textarea',
            style: 'width:30%',
            align: 'center',
            sortable: false
          },
        ],
      },
      gridCheckList: {
        columns: [
          {
            name: 'checklistName',
            field: 'checklistName',
            label: '체크리스트 항목',
            type: 'custom',
            align: 'left',
            style: 'width:70%',
            sortable: false,
          },
          {
            name: "selectValue",
            field: "selectValue",
            label: "결과",
            style: 'width:20%',
            type: 'custom',
            headType: 'select',
            align: "center",
            setHeader: true,
            codeGroupCd: 'CTO_GOOD_BAD_CD',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '',
            align: 'center',
            style: 'width:10%',
            type: 'custom',
            sortable: false
          },
        ],
      },
      editable: true,
      isSave: false,
      isComplete: false,
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
      saveType: 'POST',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      getChecklistUrl: '',
    };
  },
  computed: {
    sopProcessName() {
      let name = '';
      if (this.cto.mdmSopId) {
        name = `${this.cto.sopName} / ${this.cto.processName}`
      }
      return name;
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['checklistName'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/mobileTableImpr.vue'}`);
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.cto.insert.url;
      this.deleteUrl = transactionConfig.sop.cto.delete.url;
      this.completeUrl = transactionConfig.sop.cto.complete.url;
      // code setting
      // list setting
    },
    addActivity() {
      this.cto.activityModels.splice(0, 0, {
        sopCtoId: this.popupParam.sopCtoId,
        sopCtoActivityId: uid(),
        goodBadCd: 'CGB0000003',
        mainActivity: '',
        remarks: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C'
      })
    },
    removeActivity() {
      let selectData = this.$refs['activityTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.cto.deleteActivityModels) {
            this.cto.deleteActivityModels = []
          }
          if (this.$_.findIndex(this.cto.deleteActivityModels, { sopCtoActivityId: item.sopCtoActivityId }) === -1
            && item.editFlag !== 'C') {
              this.cto.deleteActivityModels.push(item)
          }
          this.cto.activityModels = this.$_.reject(this.cto.activityModels, item)
        })
      }
    },
    openSop() {
      this.popupOptions.title = 'SOP 검색'; // SOP 검색
      this.popupOptions.param = {
        type: 'single',
        searchType: '1', //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/sopPop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.isSelectPopup = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSopPopup;
    },
    closeSopPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.cto.mdmSopId = data[0].mdmSopId;
        this.cto.sopName = data[0].sopName;
        this.cto.processName = data[0].processName;
      }
    },
    removeSop() {
      this.cto.mdmSopId = '';
      this.cto.sopName = '';
    },
    saveCto() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.cto.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.cto.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.cto.regUserId = this.$store.getters.user.userId
              this.cto.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopCtoId', result.data)
      this.$emit('getDetail')
    },
    completeCto() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '완료하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.cto.regUserId = this.$store.getters.user.userId
          this.cto.chgUserId = this.$store.getters.user.userId
          
          this.isComplete = !this.isComplete;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail')
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopCtoId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup', 'REMOVE');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    closePopUps() {
      this.$emit('closePopup')
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "기본정보" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    required: "",
                    readonly: true,
                    editable: _vm.editable,
                    afterIcon: !_vm.disabled
                      ? [
                          {
                            name: "search",
                            click: true,
                            callbackName: "searchSop",
                            color: "teal",
                          },
                          {
                            name: "close",
                            click: true,
                            callbackName: "removeSop",
                            color: "red",
                          },
                        ]
                      : null,
                    label: "관찰작업 / 공정",
                    name: "sopProcessName",
                  },
                  on: { searchSop: _vm.openSop, removeSop: _vm.removeSop },
                  model: {
                    value: _vm.sopProcessName,
                    callback: function ($$v) {
                      _vm.sopProcessName = $$v
                    },
                    expression: "sopProcessName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    type: "date",
                    label: "관찰일",
                    name: "ctoDate",
                  },
                  model: {
                    value: _vm.cto.ctoDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "ctoDate", $$v)
                    },
                    expression: "cto.ctoDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    minuteStep: 10,
                    type: "time",
                    label: "소요시간",
                    name: "ctoTime",
                  },
                  model: {
                    value: _vm.cto.ctoTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "ctoTime", $$v)
                    },
                    expression: "cto.ctoTime",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-multi-field", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    data: _vm.cto,
                    label: "관찰자",
                    name: "observeUserId",
                  },
                  model: {
                    value: _vm.cto.observeUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "observeUserId", $$v)
                    },
                    expression: "cto.observeUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    suffix: "명",
                    type: "number",
                    label: "관찰인원",
                    name: "observeCount",
                  },
                  model: {
                    value: _vm.cto.observeCount,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "observeCount", $$v)
                    },
                    expression: "cto.observeCount",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: {
                    required: "",
                    disabled: _vm.disabled || _vm.isOld,
                    editable: _vm.editable,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.cto.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "plantCd", $$v)
                    },
                    expression: "cto.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "작업구역",
                    name: "workArea",
                  },
                  model: {
                    value: _vm.cto.workArea,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "workArea", $$v)
                    },
                    expression: "cto.workArea",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "총평" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "우선사항",
                    name: "priorityContents",
                  },
                  model: {
                    value: _vm.cto.priorityContents,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "priorityContents", $$v)
                    },
                    expression: "cto.priorityContents",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "개선사항",
                    name: "improveContents",
                  },
                  model: {
                    value: _vm.cto.improveContents,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "improveContents", $$v)
                    },
                    expression: "cto.improveContents",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-multi-select", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    codeGroupCd: "CTO_WORK_CHARACTER_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    maxValues: "3",
                    name: "workCharacteristics",
                    label: "작업특성",
                  },
                  model: {
                    value: _vm.cto.workCharacteristics,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "workCharacteristics", $$v)
                    },
                    expression: "cto.workCharacteristics",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-multi-select", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    codeGroupCd: "CTO_HUMAN_FACTOR_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    maxValues: "3",
                    name: "humanFactors",
                    label: "인적요소",
                  },
                  model: {
                    value: _vm.cto.humanFactors,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "humanFactors", $$v)
                    },
                    expression: "cto.humanFactors",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-multi-select", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    codeGroupCd: "CTO_CORE_ACTION_ITEMS_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    maxValues: "3",
                    name: "coreActionItems",
                    label: "핵심행동항목",
                  },
                  model: {
                    value: _vm.cto.coreActionItems,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "coreActionItems", $$v)
                    },
                    expression: "cto.coreActionItems",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "activityTable",
          attrs: {
            title: "핵심행동",
            columns: _vm.grid.columns,
            data: _vm.cto.activityModels,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            noDataLabel: "핵심행동을 추가하세요.",
            hideBottom: true,
            isTitle: true,
            editable: _vm.editable && !_vm.disabled,
            rowKey: "sopCtoActivityId",
            selection: "multiple",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "추가",
                          icon: "add",
                        },
                        on: { btnClicked: _vm.addActivity },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.cto.activityModels.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "제외",
                          icon: "remove",
                        },
                        on: { btnClicked: _vm.removeActivity },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.isOld
        ? _c("c-table", {
            ref: "table",
            attrs: {
              isTitle: true,
              title: "체크리스트 목록",
              columns: _vm.gridCheckList.columns,
              data: _vm.cto.ctoChecklistModels,
              filtering: false,
              gridHeightAuto: true,
              columnSetting: false,
              usePaging: false,
              noDataLabel: "체크리스트를 추가하세요.",
              hideBottom: true,
              editable: _vm.editable && !_vm.disabled,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "checklistName"
                        ? [
                            props.row.disable
                              ? _c(
                                  "font",
                                  {
                                    staticStyle: {
                                      "font-style": "italic",
                                      "font-size": "15px",
                                    },
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(_vm._s(props.row.checklistName)),
                                    ]),
                                  ]
                                )
                              : _c("font", [
                                  _vm._v(
                                    " " + _vm._s(props.row.checklistName) + " "
                                  ),
                                ]),
                          ]
                        : _vm._e(),
                      col.name === "selectValue"
                        ? [
                            !props.row.disable
                              ? _c("c-select", {
                                  attrs: {
                                    stype: "tableselect",
                                    disabled: _vm.disabled,
                                    codeGroupCd: "CTO_GOOD_BAD_CD",
                                    editable: _vm.editable,
                                    type: "edit",
                                    itemValue: "code",
                                    itemText: "codeName",
                                  },
                                  on: {
                                    datachange: function ($event) {
                                      return _vm.datachange(props)
                                    },
                                  },
                                  model: {
                                    value: props.row["selectValue"],
                                    callback: function ($$v) {
                                      _vm.$set(props.row, "selectValue", $$v)
                                    },
                                    expression: "props.row['selectValue']",
                                  },
                                })
                              : _vm._e(),
                          ]
                        : col.name === "customCol" && !props.row.disable
                        ? [
                            _c(_vm.imprComponent, {
                              tag: "component",
                              attrs: {
                                col: col,
                                props: props,
                                inputEditable: _vm.editable && !_vm.disabled,
                                isImmShow: true,
                                requestContentsCols: _vm.requestContentsCols,
                                tableKey: "sopCtoChecklistId",
                                ibmTaskTypeCd: "ITT0000060",
                                ibmTaskUnderTypeCd: "ITTU000095",
                                acceptBtnShow: true,
                              },
                              on: { imprChange: _vm.imprChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "customArea",
                                    fn: function ({ props }) {
                                      return [
                                        !props.row.disable
                                          ? _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mini-dailog-title",
                                                  },
                                                  [_vm._v("비고")]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !props.row.disable
                                          ? _c(
                                              "div",
                                              { staticClass: "col-12" },
                                              [
                                                _c("c-text", {
                                                  attrs: {
                                                    editable:
                                                      _vm.editable &&
                                                      !_vm.disabled,
                                                    label: "",
                                                    name: "remarks",
                                                  },
                                                  model: {
                                                    value: props.row.remarks,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        props.row,
                                                        "remarks",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "props.row.remarks",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              3611661810
            ),
          })
        : _vm._e(),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.disabled && _vm.isOld && _vm.editable,
                      expression: "!disabled && isOld && editable",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isComplete,
                    url: _vm.completeUrl,
                    param: _vm.cto,
                    mappingType: "PUT",
                    label: "완료",
                    icon: "check",
                  },
                  on: {
                    beforeAction: _vm.completeCto,
                    btnCallback: _vm.completeCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.disabled && _vm.editable,
                      expression: "!disabled && editable",
                    },
                  ],
                  attrs: {
                    flat: true,
                    url: _vm.saveUrl,
                    isSubmit: _vm.isSave,
                    param: _vm.cto,
                    mappingType: _vm.saveType,
                    label: "저장",
                    icon: "save",
                  },
                  on: {
                    beforeAction: _vm.saveCto,
                    btnCallback: _vm.saveCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.disabled && _vm.isOld && _vm.editable,
                      expression: "!disabled && isOld && editable",
                    },
                  ],
                  attrs: { flat: true, label: "삭제", icon: "delete_forever" },
                  on: { btnClicked: _vm.remove },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }